/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Andulka Sans;
  src: url('./assets/font/Andulka\ Sans\ Text.otf');
}

@font-face {
  font-family: Rubik;
  src: url('./assets/font/Rubik-VariableFont_wght.ttf');
}

@font-face {
  font-family: DidactGothic-Regular;
  src: url('./assets/font/DidactGothic-Regular.ttf');
}

@font-face {
  font-family: Askan;
  src: url('./assets/font/Hoftype\ \ Askan.otf');
}

@font-face {
  font-family: Atomic Marker;
  src: url('./assets/font/Atomic\ Marker\ Regular.otf');
}

@font-face {
  font-family: Austin-Bold;
  src: url('./assets/font/Austin\ Cyr-Bold.otf');
}

@font-face {
  font-family: Austin-Italic;
  src: url('./assets/font/Austin\ Cyr-Italic.otf');
}

@font-face {
  font-family: Satoshi;
  src: url('./assets/font/Satoshi-Variable.ttf');
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #FFFFFF !important;
  color: #212529 !important;
  font-family: 'Satoshi' !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ New site Css ************/
.section {
  z-index: 1;
  background-color: #fff;
  border: 1px #000;
  border-radius: 0;
  margin-bottom: -1px;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  display: block;
  position: relative;
}

.parallax-wrapper {
  border-radius: 0;
}

.container {
  max-height: none;
  max-width: none;
  opacity: 1;
  padding: 0 3rem 0 5rem;
  display: block;
  overflow: visible;
}



.mast-grid {
  height: 95vh;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  border: 5px #000;
  border-radius: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 100px;
  padding-bottom: 50px;
  display: flex;
}

.mast-left {
  width: auto;
  height: 140%;
  max-width: 30vw;
  grid-column-gap: 5vw;
  grid-row-gap: 3vw;
  border: 2px #000;
  flex-flow: wrap;
  align-content: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.buttonmasthover.button-home {
  width: 70px;
  margin-top: 10rem;
  font-size: 37px;
  line-height: 140%;
}

.button-home {
  width: 80px;
  height: 80px;
  color: #fff;
  text-align: center;
  -webkit-text-fill-color: inherit;
  background-color: #000;
  background-clip: border-box;
  border: 1px #000;
  border-radius: 0;
  justify-content: center;
  align-self: auto;
  align-items: flex-end;
  margin-right: 0;
  padding: 15px 0;
  font-family: 'Austin-Italic';
  font-size: 70px;
  line-height: 80%;
  display: flex;
  transform: rotate(-10deg);
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  /* background-color: #3898EC; */
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.div-block-63 {
  cursor: auto;
  flex-direction: column;
  flex: 0 auto;
  justify-content: flex-end;
  align-self: center;
  align-items: center;
  margin: -2rem 1rem 0 -3rem;
  display: flex;
  position: relative;
  transform: translate(0, 6vh);
}

* {
  box-sizing: border-box;
}

.div-button-about {
  width: 150px;
  height: 70px;
  order: 0;
  align-self: auto;
  display: block;
  position: static;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  transform: rotate(-15deg);
}

.hey {
  width: 8vw;
  height: 11vw;
  cursor: pointer;
  object-fit: contain;
  background-color: #ff9b8b;
  border-radius: 10vw;
  justify-content: center;
  align-self: auto;
  align-items: center;
  padding: 18px 20px;
  text-decoration: none;
  display: flex;
  position: relative;
  transform: rotate(20deg);
}

.w-inline-block {
  max-width: 100%;
}

.avatar.hide-desktop {
  border-radius: 13px;
}

.avatar {
  z-index: auto;
  width: 12vw;
  height: 17vw;
  clear: right;
  object-fit: cover;
  border-radius: 16px;
  align-self: center;
  display: block;
  position: relative;
  top: -2vw;
  bottom: auto;
  left: 6.5vw;
  right: auto;
  transform: rotate(5deg);
}

.buttonmasthover.div-button-about {
  cursor: pointer;
}

.about-button-no-dots {
  width: 151px;
  height: 70px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  cursor: pointer;
  border: 2px solid #3755db;
  border-radius: 0;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: center;
  display: grid;
  position: absolute;
}

.button-about-2 {
  width: 148px;
  height: 69px;
  color: #fff;
  text-align: center;
  -webkit-text-fill-color: inherit;
  background-color: #3755db;
  background-clip: border-box;
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 15px 20px;
  font-family: 'Andulka Sans';
  font-size: 1.5vw;
  line-height: 24px;
  display: flex;
}

.dots {
  width: auto;
  height: auto;
  grid-column-gap: 61px;
  grid-row-gap: 55px;
  cursor: pointer;
  border: 2px #3755db;
  border-radius: 0;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  justify-items: stretch;
  margin: 0;
  padding: 0;
  display: grid;
  transform: scale(1.05)translate(0, -2px);
}

#w-node-_8b5f9212-3f17-407f-d140-509c4046460e-4f8d20bd {
  justify-self: start;
}

.dot-2 {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid #3755db;
  border-radius: 0;
  transform: translate(0);
}

#w-node-_8b5f9212-3f17-407f-d140-509c4046460f-4f8d20bd {
  align-self: auto;
  justify-self: end;
}

#w-node-_8b5f9212-3f17-407f-d140-509c40464611-4f8d20bd {
  justify-self: end;
}

.buttonmasthover.button-contact {
  color: #000;
  cursor: pointer;
  background-color: #88efff;
  border-radius: 100px;
  margin-top: auto;
}

.button-contact {
  width: 14vw;
  height: 3vw;
  color: #000;
  text-align: center;
  -webkit-text-fill-color: inherit;
  background-color: #5ece7e;
  background-clip: border-box;
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  font-family: 'Andulka Sans';
  font-size: 1.5vw;
  line-height: 20px;
  display: flex;
  transform: rotate(6deg);
}

.buttonmasthover.button-linkedin {
  color: #000;
  cursor: pointer;
  transform-style: preserve-3d;
  background-color: #fff;
  margin-top: -5rem;
  font-weight: 700;
  box-shadow: 4px 4px 13px -4px rgba(0, 0, 0, .2);
}

.button-linkedin {
  width: 2.5vw;
  height: 2.5vw;
  color: #000;
  text-align: center;
  -webkit-text-fill-color: inherit;
  transform-style: preserve-3d;
  background-color: #88efff;
  background-clip: border-box;
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-radius: 6px;
  justify-content: center;
  align-self: auto;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: 'Andulka Sans';
  font-size: 1.5vw;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  transform: rotateX(6deg)rotateY(0)rotateZ(30deg);
}

.buttonmasthover.button-work {
  width: 10vw;
  height: 10vw;
  color: #000;
  cursor: pointer;
  background-color: #e3ff65;
  border: 1px rgba(0, 0, 0, .34);
  margin-top: 0;
  font-size: 1.5vw;
}

.button-work {
  width: 12vw;
  height: 12vw;
  color: #333;
  text-align: center;
  -webkit-text-fill-color: inherit;
  background-color: #e3ff65;
  background-clip: border-box;
  border-radius: 20px;
  justify-content: center;
  align-self: auto;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: 'Andulka Sans';
  font-size: 2vw;
  line-height: 110%;
  display: flex;
  transform: rotate(3deg);
  text-align: start;
}

.mast-right {
  height: 140%;
  max-width: none;
  min-height: auto;
  min-width: auto;
  text-align: left;
  border: 3px #000;
  flex-flow: column;
  flex: 0 auto;
  justify-content: center;
  align-self: auto;
  align-items: flex-start;
  padding-top: 50px;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
  position: static;
}

.mast-heading-div {
  grid-column-gap: 3vw;
  grid-row-gap: 3vw;
  align-items: flex-start;
  display: flex;
}

.mast-heading-2 {
  max-width: none;
  color: #000;
  margin-bottom: 25px;
  margin-left: 0;
  font-family: 'Austin-Bold';
  font-size: 8.7vw;
  font-weight: 400;
  line-height: 85%;
}

h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}

.spinned-outer {
  cursor: default;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 0;
  display: flex;
  position: relative;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
  text-decoration: none;
  cursor: pointer;
}

.circle-badge {
  width: 100%;
  cursor: pointer;
}


.asterisk-text {
  width: 100%;
  height: 40px;
  font-size: 1.5vw;
  font-family: 'Satoshi';
  max-width: none;
  clear: none;
  object-fit: fill;
  flex-wrap: nowrap;
  flex: 0 auto;
  align-content: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: flex-start;
  display: block;
  overflow: hidden;
}



.hey-text {
  color: #000;
  text-align: left;
  border: 1px #333;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: 'Andulka Sans';
  font-size: 1.5vw;
  line-height: 109%;
  text-decoration: none;
  display: block;
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  border: 0;
}

@media screen and (min-width: 1280px) {}


@media screen and (min-width: 1920px) {
  .container {
    padding-top: 100px;
    padding-left: 8rem;
    padding-right: 7rem;
  }
}



@media screen and (max-width: 991px) {}




@media screen and (max-width: 767px) {
  .section {
    border-radius: 0;
  }
}




@media screen and (max-width: 479px) {
  .mast-grid {
    max-height: 30vh;
  }
}

/************ Banner Css ************/
/* @media (max-width: 768px) {

} */

/* Further adjustments for small devices (phones) */
/* @media (max-width: 480px) {
} */

/************ Skills Css ************/
.skill {
  padding: 0 0 0 0;
  position: relative;
  margin-top: 5%;
  display: flex;
  flex-direction: row;
}

.skill-bx {
  border-radius: 64px;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;

}

.skill-h {
  width: 100%;
}

.skill-p {
  width: 100%;
}

.skill h2 {
  font-weight: 400;
  color: #1e242c;

  font-family: 'Austin-Italic';
  font-size: 83px;
  line-height: 65.8px;
  font-style: normal;
  letter-spacing: -.02em;
  min-height: 50px;
}

.skill h5 {
  color: #B8B8B8;
}

.skill p {
  color: #1e242c;
  font-size: 22px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 35px 0 35px 0;
  font-family: 'Satoshi';
  font-weight: 400;
  font-style: normal;
  letter-spacing: -.02em;
  min-height: 50px;
  text-align: start;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
  animation: updown 10s linear infinite;
}

.background-image-left {
  top: 88%;
  position: absolute;
  bottom: 0;
  width: 30%;
  left: -99px;
  transform: rotate(-21deg);
  z-index: -6;
}


.cloud1 {
  top: -20%;
  position: absolute;
  bottom: 0;
  width: 27%;
  left: -90px;
  transform: rotate(-25deg);
  z-index: -6;
}

.cloud2 {
  top: -38%;
  position: absolute;
  bottom: 0;
  width: 30%;
  right: -8%;
  transform: rotate(7deg);
  z-index: -6;
}

.cloud3 {
  top: 19%;
  position: absolute;
  bottom: 0;
  width: 26%;
  right: -5%;
  transform: rotate(12deg);
  z-index: -6;
}

.cloud-sm1 {
  top: 11%;
  position: absolute;
  bottom: 0;
  width: 30%;
  left: -99px;
  transform: rotate(-21deg);
  z-index: -6;
}

.cloud-sm2 {
  top: -31%;
  position: absolute;
  bottom: 0;
  width: 30%;
  left: 42%;
  transform: rotate(-21deg);
  z-index: -6;
}

.cloud-sm3 {
  top: -37%;
  position: absolute;
  bottom: 0;
  width: 30%;
  left: 26%;
  transform: rotate(-21deg);
  z-index: -6;
}

/************ Projects Css ************/
.project-card {
  width: 200px;
  height: 250px;
  margin: 1rem;
  padding: 1rem;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.project-card-arrow {
  position: absolute;
  bottom: 12px;
  right: 5px;
  font-size: 11vw;
  font-family: 'Rubik';
  color: rgba(0, 0, 0, 0.1);
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2);
}

.project-content h3,
.project-content p {
  margin: 0;
}

.project-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.project-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.project-popup-content h3,
.project-popup-content p {
  color: white;
  /* Adjust the color so it's visible on the colored background */
}

.projects {
  text-align: center;
}

.project-card {
  width: 200px;
  height: 250px;
  margin: 1rem;
  padding: 1rem;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  position: absolute;
}

.project-card h3 {
  margin-top: 0;
  color: #312f2f;

}

.project-card h3,
.project-card p {
  position: relative;
  /* Ensure the text appears above the skewed ::before */
  z-index: 1;
  font-family: 'Andulka Sans';
}


/* Positioning the project cards manually */
.project-card:nth-child(2) {
  top: 17%;
  left: 7%;
  transform: rotate(-16deg);
}

.project-card:nth-child(3) {
  top: 24%;
  left: 33%;
  transform: rotate(11deg);
}

.project-card:nth-child(4) {
  top: 61%;
  left: 18%;
  transform: rotate(16deg);
}

.project-card:nth-child(5) {
  top: 16%;
  left: 81%;
  transform: rotate(12deg);
}

.project-card:nth-child(6) {
  top: 35%;
  left: 60%;
  transform: rotate(-10deg);
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2);
}

.projects-container {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 4rem;
}


.projects-container h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.project-card {
  width: 200px;
  height: 250px;
  margin: 1rem;
  overflow: hidden;
  position: absolute;
  transform: rotate(-5deg);
  /* Rotate the card for trapezoid effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.project-card::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50px;
  /* Adjust to create the trapezoid shape */
  right: -50px;
  /* Adjust to create the trapezoid shape */
  background: inherit;
  transform: skewY(5deg);
  /* Skew the ::before content for trapezoid effect */
  z-index: -1;
}



.project-card-tags {
  position: absolute;
  bottom: 10px;
  /* Adjust as needed */
  left: 10px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
  text-align: left;
}

.project-tag {
  width: auto;
  float: left;
  opacity: 1;
  color: rgba(51, 51, 51, 0.90);
  letter-spacing: .5px;
  background-color: rgba(0, 0, 0, 0);
  border: 0.5px solid rgb(0 0 0 / 40%);
  border-radius: 20px;
  align-self: flex-start;
  margin-left: 0;
  margin-right: 8px;
  padding: 1px 8px;
  font-family: 'Andulka Sans';
  font-style: italic;
  font-size: 13px;
  font-weight: 300;
  display: block;
  position: static;
}

.project-card:hover {
  transform: scale(1.05) rotate(-5deg);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2);
}

/************ Projects Css ************/
.contact {
  /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
  /* background-image: url('./assets/img/website-laptop-desk.jpeg'); */
  /* padding: 60px 0 200px 0; */
  padding: 0 0 50px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10.4%;
  color: #1e242c;
  font-size: 22px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 35px 0 35px 0;
  font-family: 'Satoshi';
  font-weight: 400;
  font-style: normal;
  letter-spacing: -.02em;
  min-height: 50px;
  text-align: start;
}

.contact h2 {
  font-weight: 400;
  color: #1e242c;
  font-family: "Austin-Italic";
  font-size: 83px;
  line-height: 65.8px;
  font-style: normal;
  letter-spacing: -.02em;
  min-height: 50px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}

.contact-image-left {
  top: 81.25%;
  position: absolute;
  bottom: 0;
  width: 32%;
  left: 3%;
  transform: rotate(-10deg);
  z-index: 2;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  /* background-image: url('./assets/img/footer-bg.png'); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-color: #A98B6F;
}

.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}

.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}

.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}

.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}